<template>
  <div>
    <label v-text="label"></label>

    <div v-if="loading" class="col-12 text-center">
      <div
        class="m-loader m-loader--danger"
        style="width: 30px; display: inline-block"
      ></div>
    </div>

    <select
      v-else
      :disabled="disabled"
      :multiple="multiple"
      class="form-control m-input m-input--square"
      v-model="value.regions"
    >
      <option
        v-for="(region, index) in regions"
        :key="index"
        :value="region.id"
      >
        {{ region.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { fetchAllRegions } from "../services/RegionService";

export default {
  name: "RegionsSelect",

  props: {
    value: {
      type: Object,
      required: true
    },

    label: {
      type: String,
      default: "Regiões"
    },

    multiple: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.loading = true;

    fetchAllRegions()
      .then(res => {
        this.regions = res.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },

  data() {
    return {
      loading: false,
      regions: []
    };
  }
};
</script>